import React, { useState } from "react";
import { Button, Image, Form, Input } from "antd";
import { useOrderUpdateMutation } from "./service/order-api";
import { IMAGE_S3_URL } from "../../utils/api-config";
import { fallback } from "../../utils/globalvars";
import moment from "moment";
import "./styles/style.scss";

function OrderDetail({ order, handleClose }) {
  const [addPackageNumber, setAddPackageNumber] = useState(false);
  const [updatePackageNumber, setUpdatePackageNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderUpdate] = useOrderUpdateMutation();

  const onFinish = ({ colis_number }) => {
    setIsLoading(true);
    orderUpdate({ id: order.id, colis_number })
      .unwrap()
      .then(() => {
        setIsLoading(false);
        setUpdatePackageNumber(false);
        handleClose(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error:", error.data);
      });
  };

  return (
    <div className="order-page detail">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <div>
          <h4>
            Nº de commande : <span>{order.order_number}</span>
          </h4>
          <h4>
            Commande passée le:{" "}
            <span>{moment(order.createdAt).format("lll")}</span>
          </h4>
          <h4>
            Adresse de livraison : <span>{order.delivery_address}</span>
          </h4>
          <h4>
            Adresse de facturation : <span>{order.billing_address}</span>
          </h4>
          <h4>
            Statut : <span>{order.status}</span>
          </h4>
        </div>

        <div>
          <h4>
            Nom :{" "}
            <span>
              {order.firstname} {order.lastname}
            </span>
          </h4>
          <h4>
            Prénom : <span>{order.lastname}</span>
          </h4>
          <h4>
            Téléphone : <span>{order.phone}</span>
          </h4>
          <h4>
            Email : <span>{order.email}</span>
          </h4>
          <h4>
            {order.colis_number ? (
              <>
                {updatePackageNumber ? (
                  <Form
                    name="basic"
                    initialValues={{ colis_number: order.colis_number }}
                    onFinish={onFinish}
                    layout={"inline"}
                  >
                    <Form.Item
                      name="colis_number"
                      rules={[{ required: true, message: "Champs requis!" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Button
                      type="default"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Modifier
                    </Button>
                  </Form>
                ) : (
                  <>
                    Numéro de colis :
                    <span
                      className="package-number"
                      onClick={() => setUpdatePackageNumber(true)}
                    >
                      {" "}
                      {order.colis_number}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {addPackageNumber ? (
                  <Form
                    name="basic"
                    initialValues={{ colis_number: order.colis_number }}
                    onFinish={onFinish}
                    layout={"inline"}
                  >
                    <Form.Item
                      name="colis_number"
                      rules={[{ required: true, message: "Champs requis!" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Button
                      type="default"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Ajouter
                    </Button>
                  </Form>
                ) : (
                  <Button
                    type="default"
                    htmlType="submit"
                    onClick={() => setAddPackageNumber(true)}
                  >
                    Ajouter numéro de colis
                  </Button>
                )}
              </>
            )}
          </h4>
        </div>
      </div>
      <br />
      <table className="table">
        <thead>
          <th>Article</th>
          <th>Date</th>
          <th>Quantité</th>
          <th>Montant</th>
        </thead>
        {order.cart.map((item, index) => {
          return (
            <tbody>
              <tr key={index}>
                <td>
                  <Image
                    width={80}
                    src={`${IMAGE_S3_URL}/${item.image}`}
                    preview={false}
                    fallback={fallback}
                  />
                  <span className="title"> {item.libelle}</span>
                </td>
                <td>{moment(item.createdAt).format("lll")}</td>
                <td>{item.quantity}</td>
                <td>{item.price} €</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
}

export default OrderDetail;
