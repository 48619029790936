import React from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { MenuOutlined, LoginOutlined } from "@ant-design/icons";
import "./styles/style.scss";
import { removeTokenState } from "../../utils/local-storage";
import { useDispatch } from "react-redux";
import { setChatToken, setToken } from "../../features/auth/service/auth-slice";

const { Sider } = Layout;

export function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(setToken(null));
    dispatch(setChatToken(null));
    removeTokenState();
    navigate("/");
  };

  return (
    <>
      <Sider className="sidebar">
        <div className="logo">
          <img src="/assets/img/logo.svg" alt="img" className="iconHome" />
        </div>
        <br />
        <Menu
          selectedKeys={[location.pathname]}
          defaultSelectedKeys={["/"]}
          mode="inline"
        >
          <Menu.Item key="/dashboard">
            <NavLink to="/dashboard">
              <MenuOutlined />
              <span>Dashboard</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/user/professional">
            <NavLink to={"/user/professional"} state={{ title: "Coiffeuses" }}>
              <MenuOutlined />
              <span>Coiffeuses</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/user/customer">
            <NavLink to={"/user/customer"} state={{ title: "Clientes" }}>
              <MenuOutlined />
              <span>Clientes</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/appointments">
            <NavLink to="/appointments">
              <MenuOutlined />
              <span>Rendez-vous</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/payments">
            <NavLink to="/payments">
              <MenuOutlined />
              <span>Paiements</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/products">
            <NavLink to="/products">
              <MenuOutlined />
              <span>Boutique</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/orders">
            <NavLink to="/orders">
              <MenuOutlined />
              <span>Commandes</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/messages">
            <NavLink to="/messages">
              <MenuOutlined />
              <span>Mes Messages</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item onClick={() => handleLogout()} key={"logout"}>
            <span className="logout-link">
              <LoginOutlined />
              <span>Deconnexion</span>
            </span>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
}

export default Sidebar;
