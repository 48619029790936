import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const productApi = createApi({
  reducerPath: "productApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Product"],
  endpoints: (builder) => ({
    productCreate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/product/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Product"],
    }),

    productFetch: builder.query({
      query: () => `/api/v1/product/`,
      providesTags: ["Product"],
    }),

    productFetchOne: builder.query({
      query: (id) => `/api/v1/product/${id}`,
      providesTags: ["Product"],
    }),

    productUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/product/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Product"],
    }),

    productDelete: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/product/${payload}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const {
  useProductFetchQuery,
  useProductDeleteMutation,
  useProductFetchOneQuery,
  useProductUpdateMutation,
  useProductCreateMutation,
} = productApi;
