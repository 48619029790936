import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const mailApi = createApi({
  reducerPath: "mailApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Mail"],
  endpoints: (builder) => ({
    mailMessageNotification: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/mail/new/message`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Mail"],
    }),
  }),
});

export const { useMailMessageNotificationMutation } = mailApi;
