import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Navigate,
} from "react-router-dom";
import TopBar from "./components/TopBar";
import { Layout } from "antd";
import Sidebar from "./components/Sidebar";
import HomePage from "./features/dashboard";
import AppointmentList from "./features/appointment/appointment-list";
import OrderList from "./features/order/order-list";
import ProductList from "./features/product/product-list";
import ProductEdit from "./features/product/product-edit";
import LoginPage from "./features/auth/login-page";
import UserListPage from "./features/user/user-list-page";
import ProductCreate from "./features/product/product-create";
import PaymentList from "./features/payments/payment-list";
import { useSelector } from "react-redux";
import Message from "./features/message/index";
import PublicWrapper from "./components/PublicWrapper/PublicWrapper";

const { Content } = Layout;

function App() {
  const auth = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateWrapper auth={auth} />}>
          <Route path="/dashboard" element={<HomePage />} />
          <Route path="/user/:role" element={<UserListPage />} />
          <Route path="/appointments" element={<AppointmentList />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/payments" element={<PaymentList />} />
          <Route path="/products/create" element={<ProductCreate />} />
          <Route path="/products/edit/:id/:slug" element={<ProductEdit />} />
          <Route path="/messages" element={<Message />} />
        </Route>
        <Route element={<PublicWrapper auth={auth} />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LoginPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const PrivateWrapper = ({ auth }) => {
  return (
    <>
      {auth.token ? (
        <Layout>
          <Sidebar />
          <Layout className="site-layout">
            <TopBar />
            <Content
              className="site-layout-background"
              style={{ margin: "24px 16px", padding: "16px" }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Navigate to={"/"} replace />
      )}
    </>
  );
};

export default App;
