import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Spin,
  Alert,
  Checkbox,
  Image,
  Row,
  Col,
  Space,
} from "antd";
import { IMAGE_S3_URL } from "../../utils/api-config";
import {
  useUserFetchOneQuery,
  useUserUpdateMutation,
} from "./service/user-api";
import "./styles/style.scss";

function UserDetailPage({ status, params, handleCancel }) {
  const user = useUserFetchOneQuery(params);
  const [checked, setChecked] = useState();
  const [userUpdate, { isLoading, isSuccess }] = useUserUpdateMutation();

  function onChange(e) {
    setChecked(e.target.checked);
  }

  useEffect(() => {
    user.isSuccess && setChecked(user.data.status);
  }, [user, isSuccess, setChecked]);

  const handleUpdate = () => {
    userUpdate({ id: user.data.id, status: checked })
      .unwrap()
      .then(() => handleCancel())
      .catch((error) => console.log("error:", error.data));
  };

  return (
    <>
      <Modal
        title="Detail"
        width={1000}
        open={status}
        footer={null}
        closable={true}
        onCancel={handleCancel}
        className="hairdresser-page"
      >
        {user.isLoading && (
          <center>
            <Spin />
          </center>
        )}
        {user.isSuccess && user.data && (
          <div className="detail">
            <Row gutter={[24, 24]}>
              <Col span={6} className="align-center">
                {user.data.image != null ? (
                  <Image
                    width={150}
                    src={`${IMAGE_S3_URL}/${user.data.image}`}
                  />
                ) : (
                  <Image width={150} src="/assets/img/avatar.png" />
                )}
              </Col>
              <Col span={8}>
                <p>
                  Nom: <span>{user.data.firstname}</span>
                </p>
                <p>
                  Prenom: <span>{user.data.lastname}</span>
                </p>
                <p>
                  Téléphone: <span>{user.data.phone}</span>
                </p>
                <p>
                  Email: <span>{user.data.email}</span>
                </p>

                <p>
                  Ville: <span>{user.data.ville}</span>
                </p>
              </Col>
              <Col span={7}>
                <p>
                  Département: <span>{user.data.departement}</span>
                </p>
                <p>
                  Quartier: <span>{user.data.quartier}</span>
                </p>

                {/* {user.data.role === "professional" && (
                  <>
                    <p>
                      Mobilité: <span>{user.data.mobilite}</span>
                    </p>
                    <p>
                      Numero IBAN: <span>{payment.data.numero}</span>
                    </p>
                    <p>
                      Présentation: <span>{user.data.description}</span>
                    </p>
                  </>
                )} */}
                <p>
                  Statut:
                  <span>
                    <Checkbox onChange={onChange} checked={checked}>
                      En ligne
                    </Checkbox>
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        )}
        {user.isError && user.error && (
          <Alert message={user.error} type="error" showIcon />
        )}
        <br />
        <Space>
          <Button type="default" onClick={() => handleCancel()}>
            Annuler
          </Button>
          <Button
            type="secondary"
            onClick={() => handleUpdate()}
            loading={isLoading}
          >
            Sauvegarder
          </Button>
        </Space>
      </Modal>
    </>
  );
}

export default UserDetailPage;
