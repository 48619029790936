// Token
export const loadTokenState = () => {
  try {
    const serializedState = localStorage.getItem("token");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const loadChatTokenState = () => {
  try {
    const serializedState = localStorage.getItem("chatToken");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveTokenState = (state) => {
  try {
    const serializedToken = JSON.stringify(state.token);
    const serializedChatToken = JSON.stringify(state.chatToken);
    localStorage.setItem("token", serializedToken);
    localStorage.setItem("chatToken", serializedChatToken);
  } catch (error) {}
};

export const removeTokenState = () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("chatToken");
  } catch (error) {
    console.log("error:", error);
  }
};
