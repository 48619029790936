import React, { useState } from "react";
import { Table, Breadcrumb, PageHeader, Button, Spin, Tag, Space } from "antd";
import moment from "moment";
import {
  useAppointmentFetchQuery,
  useAppointmentUpdateMutation,
} from "./service/appointment-api";
import "./styles/style.scss";
import AppointmentDetail from "./appointment-detail";

function AppointmentList() {
  const appointment = useAppointmentFetchQuery();
  const [appointmentUpdate, { isLoading }] = useAppointmentUpdateMutation();
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState("");

  const handleCancel = (id) => {
    appointmentUpdate({ id: id, status: "Annuler" })
      .unwrap()
      .then(() => {})
      .catch((error) => console.log("error:", error.data));
  };

  const columns = [
    {
      title: "Coiffures",
      dataIndex: "libelle",
      key: "libelle",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Prix",
      key: "price",
      render: (record) => <span>{record.price} €</span>,
    },
    {
      title: "Coiffeuses",
      render: (record) => (
        <span>
          {record.coiffeuse_firstname} {record.coiffeuse_lastname}
        </span>
      ),
    },
    {
      title: "Clientes",
      render: (record) => (
        <span>
          {record.cliente_firstname} {record.cliente_lastname}
        </span>
      ),
    },
    {
      title: "Date du rendez-vous",
      key: "customer",
      render: (record) => (
        <span>{moment(record.date).format("llll")}</span>
      ),
    },
    {
      title: "Statut",
      key: "status",
      render: (record) =>
        (record.status === "Payé" && <Tag color="blue">Payé</Tag>) ||
        (record.status === "Non payé" && (
          <Tag color="red">Echèc paiement</Tag>
        )) ||
        (record.status === "Annuler" && <Tag color="gray">Annuler</Tag>),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <>
          <Space>
            <Button
              type="secondary"
              onClick={() => {
                setParams(record);
                setShowModal(true);
              }}
            >
              Détail
            </Button>
            {!record.isEnded && 
            <Button
              type="primary"
              onClick={() => handleCancel(record.id)}
              loading={isLoading}
              disabled={record.status === "Annuler" ? true : false}
            >
              Annuler
            </Button>}
          </Space>
        </>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>Rendez-vous</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader
          ghost={false}
          title="Rendez-vous"
          subTitle="List"
        ></PageHeader>
      </div>
      <div className="content">
        {appointment.isSuccess && appointment.data && (
          <Spin tip="Loading..." spinning={appointment.isFetching}>
            <Table columns={columns} dataSource={appointment.data} rowKey={(val)=>val.id}/>
          </Spin>
        )}
      </div>

      <AppointmentDetail
        status={showModal}
        params={params}
        handleCancel={() => setShowModal(false)}
      />
    </>
  );
}

export default AppointmentList;
