import React, { useState } from "react";
import {
  Button,
  Alert,
  Row,
  Col,
  Form,
  Input,
  Space,
  Upload,
  message,
  Spin,
  Image,
  Breadcrumb,
  PageHeader,
  Card,
  Badge,
} from "antd";
import {
  useProductFetchOneQuery,
  useProductUpdateMutation,
} from "./service/product-api";
import { DeleteOutlined } from "@ant-design/icons";
import { fallback } from "../../utils/globalvars";
import {
  useMediaFetchProductQuery,
  useMediaDeleteMutation,
  useMediaCreateMutation,
} from "../media/service/media-api";
import { useParams, useNavigate, Link } from "react-router-dom";
import { API_ROOT, IMAGE_S3_URL } from "../../utils/api-config";
import { useSelector } from "react-redux";
const { TextArea } = Input;
const { Dragger } = Upload;

function ProductEdit() {
  const { id, slug } = useParams();
  const navigate = useNavigate();
  const product = useProductFetchOneQuery(slug);
  const media = useMediaFetchProductQuery(id);
  const [mediaCreate] = useMediaCreateMutation();
  const [mediaDelete] = useMediaDeleteMutation();
  const [productUpdate] = useProductUpdateMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [fileName, setFileName] = useState();
  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    setIsLoading(true);
    const data = {
      libelle: values.libelle,
      description: values.description,
      price: values.price,
      image: fileName ? fileName : product.data.image,
    };
    productUpdate({ id: product.data.id, data: data })
      .unwrap()
      .then(() => {
        if (fileNames.length > 0)
          for (let index = 0; index < fileNames.length; index++) {
            mediaCreate({ image: fileNames[index], product: product.data.id })
              .unwrap()
              .then(() => navigate("/products"))
              .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
                console.log("error2: ===>", error);
              });
          }
        else navigate("/products");
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.data.message);
        console.log("error1: ===>", error);
      });
  };

  const props = {
    name: "file",
    multiple: true,
    action: `${API_ROOT}/api/v1/uploads`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        const data = [];
        for (let index = 0; index < info.fileList.length; index++) {
          data.push(info.fileList[index].name);
        }
        setFileNames(data);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const propsUnique = {
    name: "file",
    multiple: false,
    action: `${API_ROOT}/api/v1/upload`,
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setFileName(info.fileList[0].name);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const deleteImage = (value) => {
    mediaDelete(value)
      .unwrap()
      .then((res) => {
        console.log("res#", res);
      })
      .catch((error) => {
        console.log("error: ===>", error);
      });
  };

  console.log("product:", product);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/products"}>Produits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Editer</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader ghost={false} title="Produits" subTitle="Editer" />
      </div>
      <div className="content">
        {product.data && product.isSuccess && (
          <Form
            name="basic"
            layout="vertical"
            form={form}
            initialValues={{
              libelle: product.data.libelle,
              price: product.data.price,
              description: product.data.description,
            }}
            onFinish={onFinish}
          >
            <Row gutter={24}>
              <Col lg={16}>
                <Card>
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Form.Item
                        label="Libelle"
                        name="libelle"
                        rules={[{ required: true, message: "Champs réquis" }]}
                      >
                        <Input className="form-control" />
                      </Form.Item>
                      <Form.Item
                        label="Prix"
                        name="price"
                        rules={[{ required: true, message: "Champs réquis" }]}
                      >
                        <Input className="form-control" />
                      </Form.Item>
                      <Form.Item label="Description" name="description">
                        <TextArea rows={4} className="form-control" />
                      </Form.Item>

                      <Form.Item>
                        <Space>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isLoading}
                          >
                            Enregistrer
                          </Button>
                          <Button
                            type="default"
                            onClick={() => navigate("/products")}
                          >
                            Annuler
                          </Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>

                  {error && (
                    <Col span={12} offset={6}>
                      <Alert message={error} type="error" showIcon /> <br />
                    </Col>
                  )}
                </Card>
              </Col>
              <Col lg={8}>
                <Spin spinning={media.isLoading}>
                  <Card>
                    <Form.Item label="Image mise en avant">
                      <Dragger {...propsUnique}>
                        {fileName ? (
                          <p className="ant-upload-text">
                            Definir l'image mise en avant
                          </p>
                        ) : (
                          <Image
                            preview={false}
                            src={`${IMAGE_S3_URL}/${product.data.image}`}
                            fallback={fallback}
                          />
                        )}
                      </Dragger>
                    </Form.Item>
                  </Card>
                  <br />
                  <Card>
                    <Form.Item label="Catalogue">
                      <Dragger {...props}>
                        <p className="ant-upload-hint">
                          Ajouter un ou plusieurs images
                        </p>
                      </Dragger>
                    </Form.Item>

                    <div className="gallery">
                      <Row gutter={24}>
                        {media.data &&
                          media.isSuccess &&
                          media.data.map((item, index) => (
                            <Col lg={6} key={index}>
                              <Badge
                                count={
                                  <DeleteOutlined
                                    style={{ color: "#f5222d" }}
                                    onClick={() => deleteImage(item.id)}
                                  />
                                }
                              >
                                <Image
                                  src={`${IMAGE_S3_URL}/${item.image}`}
                                  fallback={fallback}
                                />
                              </Badge>
                            </Col>
                          ))}
                      </Row>
                    </div>
                  </Card>
                </Spin>
              </Col>
            </Row>
          </Form>
        )}{" "}
      </div>
    </>
  );
}
export default ProductEdit;
