import { createSlice } from '@reduxjs/toolkit';

export const dialogSlice = createSlice({
    name: 'dialog',
    initialState: {
        isError: false,
        isSuccess: false,
        successMessage: '',
        errorMessage: '',
        return: '',
        params: '',
        type: ''
    },
    reducers: {
        dialogShow: (state, { payload }) => {
            state.isError = payload.isError
            state.isSuccess = payload.isSuccess
            state.errorMessage = payload.errorMessage
            state.successMessage = payload.successMessage
            state.return = payload.return
            state.params = payload.params
            state.type = payload.type
        },

        dialogClearState: (state) => {
            state.isError = false
            state.isSuccess = false
            state.errorMessage = ""
            state.successMessage = ""
            state.return = ""
            state.params = ""
            state.type = ""
        }
    },
});

export const { dialogClearState, dialogShow } = dialogSlice.actions;
export default dialogSlice.reducer;