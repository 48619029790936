import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Media"],
  endpoints: (builder) => ({
    mediaFetch: builder.query({
      query: () => `/api/v1/media/`,
      providesTags: ["Media"],
    }),
    mediaFetchOne: builder.query({
      query: (id) => `/api/v1/media/${id}`,
      providesTags: ["Media"],
    }),

    mediaFetchProduct: builder.query({
      query: (id) => `/api/v1/media/product/${id}`,
      providesTags: ["Media"],
    }),

    mediaCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/media",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Media"],
    }),

    mediaDelete: builder.mutation({
      query: (id) => ({
        url: `/api/v1/media/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Media"],
    }),

    mediaUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/media/${payload.id}`,
        method: "PUT",
        body: payload.data,
      }),
      invalidatesTags: ["Media"],
    }),
  }),
});

export const {
  useMediaFetchQuery,
  useMediaFetchProductQuery,
  useMediaFetchOneQuery,
  useMediaCreateMutation,
  useMediaDeleteMutation,
  useMediaUpdateMutation,
} = mediaApi;
