import React, { useContext, useEffect, useState } from "react";
import { Table, Breadcrumb, PageHeader, Button, Spin, Tag } from "antd";
import { useUserFetchQuery } from "./service/user-api";
import { useParams, useLocation } from "react-router-dom";
import UserDetailPage from "./user-detail-page";
import { useSelector } from "react-redux";
import "./styles/style.scss";
import ChatBox from "../../components/chatBox/ChatBox";
import { UserContext } from "../../context/UserContext";

function UserListPage() {
  const { state } = useLocation();
  const params = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [activeConversation, setActiveConversation] = useState(null);
  const [userId, setUserId] = useState("");
  const { data: users, isLoading } = useUserFetchQuery(params.role);
  const { user } = useSelector((state) => state.auth);
  const chatClient = useContext(UserContext);

  useEffect(() => {
    setShowChat(false);
  }, [params.role]);

  const handleChat = async (record) => {
    let conversation = null;
    try {
      conversation = await chatClient.getConversationByUniqueName(
        `${user.firstname + "-" + user.lastname} avec ${
          record.firstname + "-" + record.lastname
        }`
      );
      setActiveConversation(conversation);
      setShowChat(true);
    } catch (error) {
      conversation = await chatClient.createConversation({
        attributes: {
          user1: {
            id: record.id,
            name: `${record.firstname} ${record.lastname}`,
            email: record.email,
          },
          user2: {
            id: user.userId,
            name: `${user.firstname} ${user.lastname}`,
            email: user.userEmail,
          },
          admin: true,
        },
        friendlyName: "Admin-Chat",
        uniqueName: `${user.firstname + "-" + user.lastname} avec ${
          record.firstname + "-" + record.lastname
        }`,
      });
      await Promise.all([
        conversation.join(),
        conversation.add(`${record.email}`),
      ]);
      setActiveConversation(conversation);
      setShowChat(true);
    }
  };

  const columns = [
    {
      title: "Nom",
      dataIndex: "firstname",
      key: "firstname",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Prénom",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Ville",
      dataIndex: "ville",
      key: "ville",
    },
    {
      title: "Statut",
      key: "status",
      render: (record) =>
        record.status === true ? (
          <Tag color="blue">En ligne</Tag>
        ) : (
          <Tag color="red">Hors ligne</Tag>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button
          type="secondary"
          onClick={() => {
            setUserId(record.id);
            setShowModal(true);
          }}
        >
          Detail
        </Button>
      ),
    },
    {
      title: "Contact",
      key: "chat",
      render: (record) => (
        <button className="contact_button" onClick={() => handleChat(record)}>
          Contact
        </button>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>{state.title}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader ghost={false} title={state.title} subTitle=""></PageHeader>
      </div>
      <div className="content">
        <Spin tip="Loading..." spinning={isLoading}>
          <Table
            columns={columns}
            dataSource={users}
            rowKey={(val) => val.id}
          />
        </Spin>
      </div>
      {users && (
        <UserDetailPage
          status={showModal}
          params={userId}
          handleCancel={() => setShowModal(false)}
        />
      )}
      {showChat && (
        <ChatBox
          setShowChat={setShowChat}
          activeConversation={activeConversation}
          bottom={true}
          chatClient={chatClient}
        />
      )}
    </>
  );
}

export default UserListPage;
