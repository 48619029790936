import React, { useState } from "react";
import {
  Button,
  Alert,
  Row,
  Col,
  Form,
  Input,
  Space,
  Upload,
  message,
  Breadcrumb,
  PageHeader,
  Card,
} from "antd";
import { useProductCreateMutation } from "./service/product-api";
import { useMediaCreateMutation } from "../media/service/media-api";
import { useNavigate, Link } from "react-router-dom";
import { API_ROOT } from "../../utils/api-config";
import { useSelector } from "react-redux";
const { TextArea } = Input;
const { Dragger } = Upload;

function ProductCreate() {
  const navigate = useNavigate();
  const [mediaCreate] = useMediaCreateMutation();
  const [productCreate] = useProductCreateMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [form] = Form.useForm();
  const { token } = useSelector((state) => state.auth);

  const onFinish = (values) => {
    setIsLoading(true);
    const data = {
      libelle: values.libelle,
      description: values.description,
      price: values.price,
      image: values.image.file.name,
    };
    productCreate(data)
      .unwrap()
      .then((res) => {
        if (fileNames.length > 0) {
          for (let index = 0; index < fileNames.length; index++) {
            mediaCreate({ image: fileNames[index], product: res.id })
              .unwrap()
              .then((res) => {
                console.log("res:", res);
                setIsLoading(false);
                navigate("/products");
              })
              .catch((error) => {
                console.log("error:", res);
                setIsLoading(false);
                setError(error.data.message);
                console.log("error2: ===>", error);
              });
          }
        } else {
          setIsLoading(false);
          navigate("/products");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.data.message);
        console.log("error1: ===>", error);
      });
  };

  const props = {
    name: "file",
    multiple: true,
    headers: { Authorization: `Bearer ${token}` },
    action: `${API_ROOT}/api/v1/uploads`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        const data = [];
        for (let index = 0; index < info.fileList.length; index++) {
          data.push(info.fileList[index].name);
        }
        setFileNames(data);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const propsUnique = {
    name: "file",
    multiple: false,
    headers: { Authorization: `Bearer ${token}` },
    action: `${API_ROOT}/api/v1/upload`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/products"}>Produits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Ajouter</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader ghost={false} title="Produits" subTitle="Editer" />
      </div>
      <div className="content">
        <Form name="basic" layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col lg={16}>
              <Card>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Form.Item
                      label="Libelle"
                      name="libelle"
                      rules={[{ required: true, message: "Champs réquis" }]}
                    >
                      <Input className="form-control" />
                    </Form.Item>
                    <Form.Item
                      label="Prix"
                      name="price"
                      rules={[{ required: true, message: "Champs réquis" }]}
                    >
                      <Input className="form-control" type="number" />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                      <TextArea rows={4} className="form-control" />
                    </Form.Item>

                    <Form.Item>
                      <Space>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={isLoading}
                        >
                          Enregistrer
                        </Button>
                        <Button
                          type="default"
                          onClick={() => navigate("/products")}
                        >
                          Annuler
                        </Button>
                      </Space>
                    </Form.Item>
                  </Col>
                </Row>

                {error && (
                  <Col span={12} offset={6}>
                    <Alert message={error} type="error" showIcon /> <br />
                  </Col>
                )}
              </Card>
            </Col>
            <Col lg={8}>
              <Card>
                <Form.Item
                  label="Image mise en avant"
                  name="image"
                  rules={[{ required: true, message: "Champs réquis" }]}
                >
                  <Dragger {...propsUnique}>
                    <p className="ant-upload-text">
                      Definir l'image mise en avant
                    </p>
                  </Dragger>
                </Form.Item>
              </Card>
              <br />
              <Card>
                <Form.Item label="Catalogue">
                  <Dragger {...props}>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Ajouter un ou plusieurs images
                    </p>
                  </Dragger>
                </Form.Item>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
export default ProductCreate;
