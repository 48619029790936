import { configureStore } from "@reduxjs/toolkit";
import dialogSlice from "../components/Dialog/slice/dialog-slice";
import { appointmentApi } from "../features/appointment/service/appointment-api";
import { orderApi } from "../features/order/service/order-api";
import { productApi } from "../features/product/service/product-api";
import { authApi } from "../features/auth/service/auth-api";
import authSlice from "../features/auth/service/auth-slice";
import { userApi } from "../features/user/service/user-api";
import { mediaApi } from "../features/media/service/media-api";
import { paymentApi } from "../features/payments/service/payment-api";
import { mailApi } from "../features/user/service/mail-api";

export default configureStore({
  reducer: {
    dialog: dialogSlice,
    auth: authSlice,
    [authApi.reducerPath]: authApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [mailApi.reducerPath]: mailApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      appointmentApi.middleware,
      orderApi.middleware,
      productApi.middleware,
      userApi.middleware,
      mediaApi.middleware,
      paymentApi.middleware,
      mailApi.middleware
    ),
});
