import React, { useState } from "react";
import {
  Table,
  Breadcrumb,
  PageHeader,
  Tag,
  Button,
  Select,
  Modal,
} from "antd";
import {
  useOrderFetchQuery,
  useOrderUpdateMutation,
} from "./service/order-api";
import "./styles/style.scss";
import moment from "moment";
import OrderDetail from "./order-detail";

function OrderList() {
  const order = useOrderFetchQuery();
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState();
  const [orderUpdate] = useOrderUpdateMutation();

  const { Option } = Select;

  const handleChange = (event, record) => {
    orderUpdate({ id: record.id, status: event })
      .unwrap()
      .then(() => {})
      .catch((error) => console.log("error:", error.data));
  };

  const columns = [
    {
      title: "Nº de commande",
      key: "order_number",
      render: (record) => (
        <span
          onClick={() => {
            setParams(record);
            setShowModal(true);
          }}
          className="view-link"
        >
          {record.order_number}
        </span>
      ),
    },
    {
      title: "Nº de colis",
      render: (record) => <span>{record.colis_number}</span>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (record) => <span>{moment(record.createdAt).format("lll")}</span>,
    },
    {
      title: "Cliente",
      render: (record) => (
        <span>
          {record.firstname} {record.lastname}
        </span>
      ),
    },
    {
      title: "Statut",
      key: "status",
      render: (record) => checkStatus(record.status),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Select
          disabled={record.status === "Livrée" ? true : false}
          defaultValue={record.status}
          style={{ width: 130 }}
          onChange={(e) => handleChange(e, record)}
        >
          <Option value="En attente">En attente</Option>
          <Option value="En cours">En cours</Option>
          <Option value="Livrée">Livrée</Option>
          <Option value="Gelée">Gelée</Option>
          <Option value="Annulée">Annuler</Option>
        </Select>
      ),
    },
  ];

  const checkStatus = (status) => {
    switch (status) {
      case "En attente":
        return <Tag color="#5b676d">En attente</Tag>;
      case "En cours":
        return <Tag color="green">En cours</Tag>;
      case "Livrée":
        return <Tag color="#87d068">Livrée</Tag>;
      case "Gelée":
        return <Tag color="red">Gelée</Tag>;
      case "Annulée":
        return <Tag color="">Annulée</Tag>;
      default:
        return <Tag color="#2db7f5">En attente</Tag>;
    }
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>Commandes</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader
          ghost={false}
          title="Commandes"
          subTitle="List"
        ></PageHeader>
      </div>
      <div className="content order-page">
        <Table
          columns={columns}
          dataSource={order.data}
          rowKey={(record) => record.id}
          loading={order.isFetching}
        />
        <Modal
          centered
          open={showModal}
          footer={null}
          width={1000}
          closable={true}
          onCancel={() => setShowModal(false)}
        >
          <div className="modal-body">
            <OrderDetail
              order={params}
              handleClose={() => setShowModal(false)}
            />
            <Button
              type="default"
              style={{ marginRight: "20px" }}
              onClick={() => setShowModal(false)}
            >
              Fermer
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default OrderList;
