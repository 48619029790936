import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const appointmentApi = createApi({
  reducerPath: "appointmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Appointment"],
  endpoints: (builder) => ({
    appointmentFetch: builder.query({
      query: () => `/api/v1/appointment`,
      providesTags: ["Appointment"],
    }),
    appointmentFetchOne: builder.query({
      query: (id) => `/api/v1/appointment/${id}`,
      providesTags: ["Appointment"],
    }),
    appointmentUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/appointment/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Appointment"],
    }),
  }),
});

export const {
  useAppointmentFetchQuery,
  useAppointmentFetchOneQuery,
  useAppointmentUpdateMutation,
} = appointmentApi;
