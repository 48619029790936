import { Layout } from "antd";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicWrapper = ({ auth }) => {
  return (
    <>
      {!auth.token ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : (
        <Navigate to={"/dashboard"} replace />
      )}
    </>
  );
};

export default PublicWrapper;
