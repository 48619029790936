import React from "react";
import { Modal, Button, Tag, Row, Col, Space } from "antd";
import moment from "moment";
import "./styles/style.scss";

function AppointmentDetail({ status, params, handleCancel }) {
  return (
    <>
      <Modal
        title="Detail"
        open={status}
        footer={null}
        closable={false}
        onCancel={handleCancel}
        className="appointment-page"
      >
        <div className="detail">
          <Row>
            <Col>
              <p>
                Coiffure: <span>{params.libelle}</span>
              </p>
              <p>
                Prix: <span>{params.price}</span>
              </p>
              <p>
                Coiffeuse:
                <span>
                  {params.coiffeuse_firstname} {params.coiffeuse_lastname}
                </span>
              </p>

              <p>
                Cliente:{" "}
                <span>
                  {params.cliente_firstname} {params.cliente_lastname}
                </span>
              </p>
              <p>
                Date du rendez-vous:{" "}
                <span>{moment(params.date).format("llll")}</span>
              </p>
              <Space>
                Statut:
                {(params.status === "Payé" && <Tag color="blue"> Payé</Tag>) ||
                  (params.status === "Non payé" && (
                    <Tag color="red"> Echèc paiement</Tag>
                  )) ||
                  (params.status === "Annuler" && (
                    <Tag color="gray"> Annuler</Tag>
                  ))}
              </Space>
            </Col>
          </Row>
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            type="secondary"
            style={{ marginRight: "20px" }}
            onClick={() => handleCancel()}
          >
            Fermer
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AppointmentDetail;
