import {
  Table,
  Space,
  Popconfirm,
  Breadcrumb,
  PageHeader,
  Spin,
  Button,
} from "antd";
import {
  useProductDeleteMutation,
  useProductFetchQuery,
} from "./service/product-api";
import { Link } from "react-router-dom";

function ProductList() {
  const product = useProductFetchQuery();
  const [productDelete, onDelete] = useProductDeleteMutation();

  const handleDelete = (id) => {
    console.log("id", id);
    productDelete(id)
      .unwrap()
      .then(() => {})
      .catch((error) => console.log("error ===>", error));
  };

  const columns = [
    {
      title: "Libelle",
      dataIndex: "libelle",
      key: "libelle",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Prix",
      dataIndex: "price",
      key: "price",
      render: (text) => <span>{text} €</span>,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (record) => (
        <Space size="middle">
          <Link to={`/products/edit/${record.id}/${record.slug}`}>Editer</Link>
          <Popconfirm
            title="Voulez-vous supprimer ?"
            onConfirm={() => handleDelete(record.id)}
          >
            <Button
              type="primary"
              size="small"
              loading={onDelete.isLoading}
              danger
            >
              Supprimer
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>Produits</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader
          ghost={false}
          title="Produits"
          subTitle="List"
          extra={[
            <Button type="primary">
              <Link to={"/products/create"}>
                <span>Ajouter un produit</span>
              </Link>
            </Button>,
          ]}
        ></PageHeader>
      </div>
      <div className="content">
        <Spin tip="Loading..." spinning={product.isLoading}>
          <Table
            rowKey={(val) => val.id}
            columns={columns}
            dataSource={product.data}
          />
        </Spin>
      </div>
    </>
  );
}
export default ProductList;
