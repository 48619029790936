import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/api-config";

export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Order"],
  endpoints: (builder) => ({
    orderFetch: builder.query({
      query: () => `/api/v1/order`,
      providesTags: ["Order"],
    }),
    orderFetchOne: builder.query({
      query: (id) => `/api/v1/order/${id}`,
      providesTags: ["Order"],
    }),
    orderUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/order/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Order"],
    }),
  }),
});

export const {
  useOrderFetchQuery,
  useOrderFetchOneQuery,
  useOrderUpdateMutation,
} = orderApi;
