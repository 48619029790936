import React from "react";
import "./styles/style.scss";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { useOrderFetchQuery } from "../order/service/order-api";
import { useUserFetchQuery } from "../user/service/user-api";
import { useAppointmentFetchQuery } from "../appointment/service/appointment-api";

export function HomePage() {
  const professional = useUserFetchQuery("professional");
  const customer = useUserFetchQuery("customer");
  const appointment = useAppointmentFetchQuery();
  const order = useOrderFetchQuery();

  return (
    <div className="home-page">
      <Row gutter={24}>
        <Col lg={6} xs={24}>
          <Card
            size="small"
            title="Total coiffeuses"
            extra={<Link to={"/user/professional"} state={{ title: "Coiffeuses" }}> Voir</Link>}
            className="blc-hairdresser"
          >
            {professional.data && professional.isSuccess && (
              <h4>{professional.data.length}</h4>
            )}
          </Card>
        </Col>
        <Col lg={6} xs={24}>
          <Card
            size="small"
            title="Total Clientes"
            extra={<Link to={"/user/customer"} state={{ title: "Clientes" }}> Voir</Link>}
            className="blc-customer"
          >
            {customer.data && customer.isSuccess && (
              <h4>{customer.data.length}</h4>
            )}
          </Card>
        </Col>
        <Col lg={6} xs={24}>
          <Card
            size="small"
            title="Total Commandes"
            extra={<Link to={"/orders"}> Voir</Link>}
            className="blc-order"
          >
            {order.data && order.isSuccess && <h4>{order.data.length}</h4>}
          </Card>
        </Col>
        <Col lg={6} xs={24}>
          <Card
            size="small"
            title="Total Rendez-vous"
            extra={<Link to={"/appointments"}> Voir</Link>}
            className="blc-order"
          >
            {appointment.data && appointment.isSuccess && (
              <h4>{appointment.data.length}</h4>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default HomePage;
