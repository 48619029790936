import React from 'react';
import "./styles/style.scss";
import { Layout } from 'antd';

const { Header } = Layout;

export function TopBar() {
    return (
        <Header className="">

        </Header>
    );
}

export default TopBar;
