import { Table, Breadcrumb, PageHeader, Button } from "antd";
import { usePaymentFetchQuery } from "./service/payment-api";
import "./styles/style.scss";
import moment from "moment";

function PaymentList() {
  const payment = usePaymentFetchQuery();

  const columns = [
    {
      title: "Coiffeuse",
      render: (record) => (
        <span style={{ textTransform: 'capitalize' }}>
          {record.hairdresser?.firstname} {record.hairdresser?.lastname}
        </span>
      ),
    },
    // {
    //   title: "Chiffre d'affaires de la semaine",
    //   render: (record) => <span>0 €</span>,
    // },

    // {
    //   title: "Chiffre d'affaires depuis inscription",
    //   render: (record) => <span>0 €</span>,
    // },

    {
      title: "Gains KIMEKOIF",
      render: (record) => {
        const amount = (record.amount / 100) - parseFloat(record.appointment?.price);
        return <span>{amount.toFixed(2)} €</span>;
      }      
    },

    // {
    //   title: "à verser",
    //   render: (record) => <span>{record.toBePaid} €</span>,
    // },

    // {
    //   title: "Déjà perçu",
    //   render: (record) => <span>{record.received} €</span>,
    // },

    {
      title: "Total des versements",
      render: (record) => <span>{(record.amount)/100} €</span>,
    },

    {
      title: "Statut",
      render: (record) => <span style={{ textTransform: 'capitalize' }}>{record.status}</span>,
    },
    // {
    //   title: "Nº de IBAN",
    //   key: "numero",
    //   render: (record) => <span>{record.numero}</span>,
    // },
    {
      title: "Date du dernier paiement",
      dataIndex: "updtedAt",
      render: (record) => <span>{moment(record).format("lll")}</span>,
    },
    {
      title: "Action",
      key: "action",
      width: 60,
      render: (record) => (
        <Button type="secondary" size="small">
          Mettre à jour
        </Button>
      ),
    },
  ];

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Accueil</Breadcrumb.Item>
        <Breadcrumb.Item>Paiement</Breadcrumb.Item>
      </Breadcrumb>

      <div className="page-header">
        <PageHeader ghost={false} title="Paiement"></PageHeader>
      </div>
      <div className="content payment-page">
        <Table
          columns={columns}
          dataSource={payment.data}
          rowKey={(record) => record.id}
          loading={payment.isFetching}
        />
      </div>
    </>
  );
}

export default PaymentList;
