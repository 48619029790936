import React, { useContext, useState } from "react";
import "./styles/style.scss";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./styles/style.scss";
import ConversationList from "./components/ConversationList/ConversationList";
import ChatBox from "../../components/chatBox/ChatBox";
import { UserContext } from "../../context/UserContext";

const Message = () => {
  const { user } = useSelector((state) => state.auth);
  const [activeConversation, setActiveConversation] = useState(null);
  const chatClient = useContext(UserContext);

  return (
    <div>
      {activeConversation && (
        <button
          className="back_button"
          onClick={() => setActiveConversation(null)}
        >
          <ArrowLeftOutlined /> Back
        </button>
      )}

      {!activeConversation ? (
        <ConversationList
          setActiveConversation={setActiveConversation}
          user={user}
        />
      ) : (
        <ChatBox
          activeConversation={activeConversation}
          chatClient={chatClient}
          closeButton={false}
        />
      )}
    </div>
  );
};

export default Message;
